
import { Component } from "vue-property-decorator";
import { IAddress, INationality, ICountry, LabelValue } from "@/types";
import Tabs from "@/components/ats/layout/Tabs.vue";
import PdfManager from "@/components/PdfManager.vue";
import { Getter } from "vuex-class";
import { formattedAddress } from "@/helpers/commons";
import InputGmapAutocomplete from "@/components/form/inputGmapAutocomplete.vue";
import PicturePreview from "@/components/PicturePreview.vue";
import { mixins } from "vue-class-component";
import CandidateMixin from "@/mixins/CandidateMixin.vue";
import moment from "moment-timezone";
import cloneDeep from "lodash.clonedeep";
import ActionButton from "@/components/ActionButton.vue";
import GeocodeInput from "@/components/form/v3/GeocodeInput.vue";

@Component({
    name: "ProfileIdentity",
    components: {
        Tabs,
        PdfManager,
        InputGmapAutocomplete,
        PicturePreview,
        ActionButton,
        GeocodeInput,
    },
})
export default class ProfileIdentity extends mixins(CandidateMixin) {
    moment = moment;

    @Getter("getNationalitiesOptions") getNationalitiesOptions!: Array<INationality>;
    @Getter("getBirthCountriesOptions") getBirthCountriesOptions!: Array<ICountry>;
    @Getter("applicationOrigin") applicationOrigin!: LabelValue[];

    loading: boolean = false;
    errors: any = {};
    readonly = false;

    get nationalitiesOptions() {
        return [
            { label: "-", value: "" },
            ...this.getNationalitiesOptions.map((n) => {
                return { label: n.name, value: n.code };
            }),
        ];
    }

    get birthCountriesOptions() {
        return [
            { label: "-", value: "" },
            ...this.getBirthCountriesOptions.map((n) => {
                return { label: n.name, value: n.code };
            }),
        ];
    }

    get generateForm() {
        return [
            {
                label: this.$t("page.candidates.add.form.firstname"),
                value: "firstname",
                type: "text",
                isRequired: true,
            },
            {
                label: this.$t("page.candidates.add.form.lastname"),
                value: "lastname",
                type: "text",
                isRequired: true,
            },
            { label: this.$t("page.candidates.add.form.email"), value: "email", type: "text", isRequired: false },
            { label: this.$t("page.candidates.add.form.phone"), value: "phone", type: "tel", isRequired: true },
            {
                label: this.$t("page.candidates.add.form.adress"),
                value: "address",
                type: "address",
                isRequired: false,
            },
            {
                label: this.$t("form.nationality"),
                value: "nationalityCode",
                type: "select",
                options: this.nationalitiesOptions,
                isRequired: false,
            },
            {
                label: this.$t("page.candidates.add.form.birthPlace"),
                value: "birthPlace",
                type: "text",
                isRequired: false,
            },
            {
                label: this.$t("page.candidates.add.form.birthCountry"),
                value: "birthCountry",
                type: "select",
                options: this.birthCountriesOptions,
                isRequired: false,
            },
            {
                label: this.$t("form.nir"),
                value: "nir",
                type: "nir",
                mask: "X XX XX XX XXX XXX",
                placeholer: "x xx xx xx xxx x",
                isRequired: false,
            },
            {
                label: this.$t("form.nirKey"),
                value: "nirKey",
                type: "nirKey",
                mask: "XX",
                placeholer: "xx",
                isRequired: false,
            },
            {
                label: this.$t("page.candidates.add.form.birthDate"),
                value: "birthDate",
                type: "txt-date",
                isRequired: false,
            },
            {
                label: this.$t("page.candidate.origin"),
                value: "origin",
                type: "select",
                options: this.applicationOrigin,
                isRequired: true,
            },
        ];
    }

    async updateCandidate() {
        this.errors = {};
        this.loading = true;
        try {
            const candidate = cloneDeep(this.candidate);
            candidate.birthDate = candidate.birthDate
                ? moment(candidate.birthDate, this.$t("date.format")).valueOf()
                : null;
            await this.saveCandidate(candidate);
            this.$toast.open({
                message: <string>this.$t("page.profile.saved"),
                type: "success",
                duration: 2000,
            });
            this.loading = false;
        } catch (e) {
            this.errors = e.errors;
            console.log(e);
            this.$toast.open({
                message: <string>this.$t("global.serverError"),
                type: "error",
                duration: 5000,
            });
            this.loading = false;
        }
    }

    addressValue(address: IAddress) {
        return formattedAddress(address);
    }

    placeChanged(address: IAddress, place: any) {
        address.street = place.street;
        address.complement = place.complement;
        address.city = place.locality;
        address.zip = place.postal_code;
        address.countryCode = place.countryCode;
        address.location = [place.longitude, place.latitude];
        address.inseeCode = place.inseeCode;
    }

    hasLocation(address: IAddress) {
        return address.location?.length === 2 && !address.location?.some((l) => !l);
    }

    beforeMount() {
        // if(this.candidate?.workerId) this.readonly = true;
        if (
            this.$route.params.notCompletedMandatoryFields &&
            Array.isArray(this.$route.params.notCompletedMandatoryFields)
        ) {
            this.$route.params.notCompletedMandatoryFields.map((notCompletedFieldKey) => {
                this.errors[notCompletedFieldKey] = { id: "Name.IsEmpty", key: notCompletedFieldKey };
            });
        }
    }

    autoNirKey(): any {
        const nir = this.candidate.nir;
        if (!nir) return null;

        const nirSansEspace = nir.replace(/ /g, "");
        const convertedNir = nirSansEspace.toLowerCase().replace("2b", "18").replace("2a", "19");
        const computeKey: number = 97 - (parseInt(convertedNir) % 97);

        this.candidate.nirKey = computeKey.toString();

        if (this.candidate.nirKey.length === 1) this.candidate.nirKey = "0" + this.candidate.nirKey;

        this.$forceUpdate();
    }

    isRequiredFieldFilled() {
        const isRequiredFieldFilled = this.generateForm
            .filter((elem) => elem.isRequired)
            .every((elem) => {
                if (elem.value === "address") {
                    return this.addressValue(this.candidate[elem.value]);
                }

                return this.candidate[elem.value];
            });
        return !isRequiredFieldFilled;
    }
}
